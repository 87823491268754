import React, { useEffect, useState } from 'react'

import { TextField } from '@mui/material'

import useDebounce from '../../lib/hooks/UseDebounce'

interface DebouncedTextProps {
  defaultValue: string
  onChange: (event) => void
  debounceTimeout?: number
  name: string
  setKey: string | number
  isRequired?: boolean
  placeholder?: string
  className?: string
  dataTestId?: string
  disabled?: boolean
  error?: boolean
  helperText?: string
  label?: string
  startAdornment?: React.ReactNode
  fullWidth?: boolean
}

const DebounceTextInput = ({
  defaultValue,
  onChange,
  debounceTimeout,
  name,
  setKey,
  isRequired = false,
  placeholder,
  className,
  dataTestId,
  disabled = false,
  error = false,
  helperText = '',
  label,
  startAdornment = null,
  fullWidth = true,
}: DebouncedTextProps) => {
  // Extra CSS styling for disabled input
  const disabledClasses = 'bg-gray-100 select-none'

  // Local state for input
  const [value, setValue] = useState<string>(defaultValue || '')

  // Debounce value with timeout
  const debouncedValue = useDebounce<number | string>(
    value,
    debounceTimeout || 750,
  )

  // Handle the input change
  const handleChange = (value: string) => {
    setValue(value)
  }

  // When the debounce is complete, update the value
  useEffect(() => {
    onChange(debouncedValue)
  }, [debouncedValue])

  // When the default value changes, update the value
  useEffect(() => {
    setValue(defaultValue)
  }, [defaultValue])

  return (
    <TextField
      InputProps={{
        startAdornment,
      }}
      key={setKey}
      id={`${name}-goal-input`}
      placeholder={placeholder}
      className={`${className} ${disabled && disabledClasses}`}
      hiddenLabel
      size="small"
      fullWidth={fullWidth}
      variant="outlined"
      name={name}
      required={isRequired}
      autoComplete="off"
      multiline
      value={value}
      onChange={(e) => handleChange(e.target.value)}
      data-testid={dataTestId}
      disabled={disabled}
      error={error}
      helperText={helperText}
      label={`${label ? label : ''}`}
    />
  )
}

export default DebounceTextInput
